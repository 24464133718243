import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Catalog/utils';
import { ENDPOINTS as COMMON_ENDPOINTS } from '../../utils/shared/constants';
import apiClient from '../axios';

export const saveCatalogItem = createAsyncThunk('catalog/saveCatalogItem', async (formValues) => {
  try {
    const { data } = await apiClient.post(ENDPOINTS.SAVE_CATALOG_ITEM, formValues);
    return { data };
  } catch (error) {
    return error?.response ? error?.response?.data : error?.message;
  }
});

export const getPurchaseUomList = createAsyncThunk('catalog/getPurchaseUom', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_PURCHASE_UOM);
  return data;
});

export const searchCatalogVendors = async (searchInput) => {
  const { data } = await apiClient.get(
    `${ENDPOINTS.SEARCH_CATALOG_VENDORS}?searchInput=${searchInput}`
  );
  return data;
};

export const searchCatalogHCPCS = async (searchInput, isCustom = false) => {
  const { data } = await apiClient.get(
    `${COMMON_ENDPOINTS.SEARCH_CATALOG_HCPCS}?searchInput=${searchInput}&is_Custom=${isCustom}&Is_Tenant_Hcpcs=${false}`
  );
  return data;
};

export const searchCatalogProduct = createAsyncThunk('catalog/fetchProductFamily', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.SEARCH_CATALOG_PRODUCT}`);
  return data;
});

export const fetchSingleCatalogItem = createAsyncThunk('catalog/fetchCatalog', async (itemId) => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_SINGLE_CATALOG_ITEM}?itemId=${itemId}`);
  return data;
});

export const fetchCatalogManufacturerData = async (searchText) => {
  const { data } = await apiClient.get(
    `${ENDPOINTS.GET_CATALOG_MANUFACTURER_LIST}?searchText=${searchText}`
  );
  return data;
};

export const fetchCatalogItemSizes = createAsyncThunk('catalog/GetAllItemSizes', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_CATALOG_ITEM_SIZES);
  return data;
});

export const fetchCatalogItemAuditDetails = createAsyncThunk(
  'CatalogManagement/GetItemAudit',
  async (itemId) => {
    const { data } = await apiClient.get(`${ENDPOINTS.CATALOG_ITEM_AUDIT_LOGS}?Item_Id=${itemId}`);
    return data;
  }
);

export const fetchCatalogCount = createAsyncThunk('catalog/fetchCatalogCount', async () => {
  const { data } = await apiClient.get(ENDPOINTS.CATALOG_COUNT);
  return data;
});

export const fetchCatalogList = createAsyncThunk(
  'catalog/fetchCatalogList',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.CATALOG_LIST, payloadData);
    return data;
  }
);

export const fetchAllCatalogVendors = createAsyncThunk(
  'reports/fetchAllCatalogVendors',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.GET_ALL_CATALOG_VENDORS);
    return data;
  }
);

export const fetchCatlogBundleSingleData = createAsyncThunk(
  'catalog/fetchCatlogBundleSingleData',
  async (bundle_id) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.CATLOG_SINGLE_BUNDLE_DATA}?Bundle_Id=${bundle_id}`
    );
    return data;
  }
);

export const validateItemNumber = createAsyncThunk(
  'catalog/validateItemNumber',
  async (payloadRequest) => {
    const { data } = await apiClient.post(ENDPOINTS.VALIDATE_ITEM_NUMBER, payloadRequest);
    return data;
  }
);

export const createBundles = createAsyncThunk(
  'catalog/createBundles',
  async (payloadData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_UPDATE_BUNDLE, payloadData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data); // This will reject the promise with an error message
    }
  }
);

export const getCatalogCategroy = createAsyncThunk(
  'catalog/getCatalogCategroy',
  async (payload) => {
    try {
      const { data } = await apiClient.get(
        `${ENDPOINTS.GET_CATALOG_CATEGORY}?HcpcsId=${payload?.hcpcs}&CustomHcpcsId=${payload?.customHcpcs}`
      );
      return { data };
    } catch (error) {
      return error?.response ? error?.response?.data : error?.message;
    }
  }
);
