import { createSlice } from '@reduxjs/toolkit';
import {
  createBundles,
  fetchAllCatalogVendors,
  fetchCatalogCount,
  fetchCatalogItemAuditDetails,
  fetchCatalogItemSizes,
  fetchCatalogList,
  fetchCatlogBundleSingleData,
  fetchSingleCatalogItem,
  getCatalogCategroy,
  getPurchaseUomList,
  saveCatalogItem,
  searchCatalogProduct
} from '../api/services/catalog.service';
import { CATALOG_MISSING_COG_STATUS_ID } from '../utils/shared/constants';

const initialState = {
  addItemModal: {
    isOpen: false,
    itemId: null,
    isEditing: null
  },
  addItemSuccess: false,
  vendorList: [],
  purchaseUOMList: [],
  catalogVendorData: [],
  allVendorsDataSingleItem: [],
  fetchSingleCatalogItemData: {},
  isPending: false,
  isLoadedTiles: true,
  isCatalogFetched: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  catalogListAPIPayload: {
    searchInput: '',
    catalogStatus: CATALOG_MISSING_COG_STATUS_ID
  },
  addBundleModal: {
    isOpen: false,
    bundleId: null,
    isEditing: null
  },
  catalogCountData: null,
  catalogList: [],
  productFamilyData: [],
  allVendors: [],
  bundleData: [],
  catalogItemData: [],
  saveCatalogItemData: [],
  catalogUpdateBundleData: [],
  itemAuditData: [],
  addBundleSuccess: false,
  itemSizeData: [],
  catalogCategory: []
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalogLists: (state, action) => {
      return { ...state, catalogList: action.payload };
    },
    setCatalogListAPIPayload: (state, action) => {
      return { ...state, catalogListAPIPayload: action.payload };
    },
    setAddItemModal: (state, action) => {
      return { ...state, addItemModal: action.payload };
    },
    setVendorList: (state, action) => {
      return { ...state, vendorList: action.payload };
    },
    setCatalogVendorData: (state, action) => {
      return { ...state, catalogVendorData: action.payload };
    },
    setallVendorsDataSingleItem: (state, action) => {
      return { ...state, allVendorsDataSingleItem: action.payload };
    },
    setAddBundleModal: (state, action) => {
      return { ...state, addBundleModal: action.payload };
    },
    setCatalogItemData: (state, action) => {
      return { ...state, catalogItemData: action.payload };
    },
    setBundleData: (state, action) => {
      return { ...state, bundleData: action.payload };
    },
    setAddItemSuccess: (state, action) => {
      return { ...state, addItemSuccess: action.payload };
    },
    setAddBundleSuccess: (state, action) => {
      return { ...state, addBundleSuccess: action.payload };
    },
    setFetchSingleCatalogItemData: (state, action) => {
      return { ...state, fetchSingleCatalogItemData: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCatalogCount.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true
        };
      })
      .addCase(fetchCatalogCount.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isSuccess: true,
          catalogCountData: action.payload
        };
      })
      .addCase(fetchCatalogCount.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchCatalogList.pending, (state) => {
        return {
          ...state,
          isCatalogFetched: true
        };
      })
      .addCase(fetchCatalogList.fulfilled, (state, action) => {
        return {
          ...state,
          isCatalogFetched: false,
          isSuccess: true,
          catalogList: action.payload
        };
      })
      .addCase(fetchCatalogList.rejected, (state, action) => {
        return {
          ...state,
          isCatalogFetched: false,
          isError: true,
          errorMessage: action.error.message,
          catalogList: []
        };
      })
      .addCase(saveCatalogItem.pending, (state) => {
        return {
          ...state,
          isPending: true,
          saveCatalogItemData: []
        };
      })
      .addCase(saveCatalogItem.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          saveCatalogItemData: action.payload,
          addItemSuccess: true
        };
      })
      .addCase(saveCatalogItem.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          saveCatalogItemData: [],
          errorMessage: action.error.message,
          addItemSuccess: false
        };
      })
      .addCase(getPurchaseUomList.pending, (state) => {
        return {
          ...state,
          isPending: true,
          purchaseUOMList: []
        };
      })
      .addCase(getPurchaseUomList.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          purchaseUOMList: action.payload
        };
      })
      .addCase(getPurchaseUomList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          purchaseUOMList: [],
          errorMessage: action.error.message
        };
      })
      .addCase(fetchSingleCatalogItem.pending, (state) => {
        return {
          ...state,
          isPending: true,
          fetchSingleCatalogItemData: []
        };
      })
      .addCase(fetchSingleCatalogItem.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchSingleCatalogItemData: action?.payload
        };
      })
      .addCase(fetchSingleCatalogItem.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          fetchSingleCatalogItemData: [],
          errorMessage: action.error.message
        };
      })
      .addCase(searchCatalogProduct.pending, (state) => {
        return {
          ...state,
          isPending: true,
          productFamilyData: []
        };
      })
      .addCase(searchCatalogProduct.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          productFamilyData: action.payload
        };
      })
      .addCase(searchCatalogProduct.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          productFamilyData: [],
          errorMessage: action.error.message
        };
      })
      .addCase(fetchAllCatalogVendors.pending, (state) => {
        return {
          ...state,
          isPending: true,
          allVendors: []
        };
      })
      .addCase(fetchAllCatalogVendors.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          allVendors: action.payload
        };
      })
      .addCase(fetchAllCatalogVendors.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          reportCount: []
        };
      })
      .addCase(createBundles.pending, (state) => {
        return {
          ...state,
          isPending: true,
          addBundleSuccess: false
        };
      })
      .addCase(createBundles.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          catalogUpdateBundleData: action.payload,
          addBundleSuccess: true
        };
      })
      .addCase(createBundles.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          addBundleSuccess: false
        };
      })
      .addCase(fetchCatlogBundleSingleData.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchCatlogBundleSingleData.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          bundleData: action?.payload?.tenantDevices,
          bundleItemData: action?.payload?.tenantDevices_Item
        };
      })
      .addCase(fetchCatlogBundleSingleData.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchCatalogItemAuditDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchCatalogItemAuditDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          itemAuditData: action.payload
        };
      })
      .addCase(fetchCatalogItemAuditDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchCatalogItemSizes.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchCatalogItemSizes.fulfilled, (state, action) => {
        const modifiedItemSizes = action?.payload?.map((item) => ({
          description: item,
          id: item
        }));
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          itemSizeData: modifiedItemSizes
        };
      })
      .addCase(fetchCatalogItemSizes.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(getCatalogCategroy.pending, (state) => {
        return {
          ...state,
          isPending: true,
          catalogCategory: []
        };
      })
      .addCase(getCatalogCategroy.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          catalogCategory: action.payload
        };
      })
      .addCase(getCatalogCategroy.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          catalogCategory: [],
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = catalogSlice;

export const {
  setCatalogLists,
  setCatalogListAPIPayload,
  setAddItemModal,
  setVendorList,
  setCatalogVendorData,
  setallVendorsDataSingleItem,
  setAddBundleModal,
  setCatalogItemData,
  setBundleData,
  setAddItemSuccess,
  setAddBundleSuccess,
  setFetchSingleCatalogItemData
} = actions;

export default reducer;
