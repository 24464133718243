import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Visits/utils';
import { DOCUMENT_TYPE_ID, Document_Type_Id } from '../../utils/shared/constants';
import { transformItemsAndBundles } from '../../utils/shared/formatHelper';
import apiClient from '../axios';

// Step 1: Search for patients
export const searchPatientByNameMRNOrDOB = createAsyncThunk(
  'newvisit/searchPatientByNameMRNOrDOB',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.VISIT_SEARCH_PATIENT, payloadData);
    return data;
  }
);

// Step 2: Search for visititem
export const searchVisitItem = createAsyncThunk(
  'newvisit/searchVisitItem',
  async ({ searchText, selectedLocation }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.VISIT_SEARCH_ITEM}/?Location_Id=${selectedLocation}&SearchItem=${searchText}`
    );
    return data;
  }
);

export const visitItemLocation = createAsyncThunk(
  'newvisit/visitItemLocation',
  async ({ visitItemId, selectedLocation }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.VISIT_ITEM_LOCATION}/${visitItemId}?locationId=${selectedLocation}`
    );
    return { item_id: visitItemId, itemLocations: data?.itemLocations };
  }
);

// Search Diagnosis Data
export const searchDiagnosis = createAsyncThunk(
  'newvisit/searchDiagnosis',
  async (DiagnosisText) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.VISIT_SEARCH_DIAGNOSIS}?searchdiagnosis=${DiagnosisText}`
    );

    return { items: data, searchText: DiagnosisText };
  }
);

export const createVisit = createAsyncThunk(
  'newvisit/createVisit',
  async (apiFormData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_CREATE_UPDATE, apiFormData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data ? error?.response?.data : '');
    }
  }
);

export const autoSaveVisit = createAsyncThunk(
  'newvisit/autoSaveVisit',
  async (apiFormData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_CREATE_UPDATE, apiFormData);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVisit = createAsyncThunk('newvisit/fetchVisit', async ({ visit_Id }) => {
  const { data } = await apiClient.get(`${ENDPOINTS.VISIT_FETCH}/${visit_Id}`);
  return data;
});

export const updateVisit = createAsyncThunk(
  'newvisit/updateVisit',
  async (apiUpdatedFormData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_CREATE_UPDATE, apiUpdatedFormData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const viewAndSignPDF = createAsyncThunk(
  'newvisit/viewAndSignPDF',
  async ({ patientId, visitId, tenantDocument_Id, document_Type_Id }) => {
    const url = `${ENDPOINTS.VIEW_AND_SIGN_PDF}?patient_Id=${patientId}&visit_Id=${visitId}&tenant_Document_Id=${tenantDocument_Id}&Document_Type_Id=${document_Type_Id}`;
    const { data } = await apiClient.get(url);
    return data;
  }
);

export const replaceDocument = createAsyncThunk(
  'newvisit/replaceDocument',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_DOCUMENT_REPLACE, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const regenerateDocument = createAsyncThunk(
  'newvisit/regenerateDocument',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.VISIT_DOCUMENT_REGENERATE, payload);
    return data;
  }
);

export const fetchDocuments = createAsyncThunk('newvisit/fetchDocuments', async ({ Visit_Id }) => {
  const { data } = await apiClient.get(`${ENDPOINTS.FETCH_DOCUMENTS}?Visit_Id=${Visit_Id}`);
  return data;
});

export const addDocument = createAsyncThunk(
  'newvisit/addDocument',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_ADD_DOCUMENTS, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const viewDocuments = createAsyncThunk(
  'newvisit/viewDocuments',
  async ({ Patient_Id, Visit_Id }) => {
    const url = `${ENDPOINTS.VISIT_VIEW_DOCUMENTS}?Patient_Id=${Patient_Id}&Visit_Id=${Visit_Id}&Document_Type_Id=${Document_Type_Id}`;
    const { data } = await apiClient.get(url);
    return data;
  }
);

export const savePDFSignDocument = createAsyncThunk(
  'newvisit/savePDFSignDocument',
  async (apiUpdatedFormData) => {
    const { data } = await apiClient.post(ENDPOINTS.SAVE_PDF_SIGN_DOCUMENTS, apiUpdatedFormData);
    return data;
  }
);

export const viewedPDF = createAsyncThunk('newvisit/viewedPDF', async (apiViewedData) => {
  const { data } = await apiClient.post(ENDPOINTS.VIEW_PDF, apiViewedData);
  return data;
});

export const fetchUploadedPDF = createAsyncThunk(
  'newvisit/fetchUploadedPDF',
  async ({ patientId, visitId }) => {
    const url = `${ENDPOINTS.FETCH_UPLOADED_PDF}&patient_Id=${patientId}&visit_Id=${visitId}&Document_Type_Id=${DOCUMENT_TYPE_ID}`;
    const { data } = await apiClient.get(url);
    return data;
  }
);

export const getItemDataByScanCode = async ({ vendor_part_number, selectedLocation }) => {
  const { data } = await apiClient.get(
    `${ENDPOINTS.VISIT_SEARCH_ITEM}?vendor_part_number=${vendor_part_number}&Location_Id=${selectedLocation}`
  );
  const itemObj = transformItemsAndBundles(data);
  return itemObj?.[0] || {};
};

export const transmitData = createAsyncThunk(
  'newvisit/transmitData',
  async (apiTransmitData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.VISIT_TRANSMIT_DOCUMENTS, apiTransmitData);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data); // This will reject the promise with an error message
    }
  }
);

export const fetchTimerSOS = createAsyncThunk(
  'newvisit/fetchTimerSOS',
  async ({ requestId, patientId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.TIMER_SOS}/?requestId=${requestId}&patientId=${patientId}`
    );
    return data;
  }
);

export const fetchTimerSNF = createAsyncThunk(
  'newvisit/fetchTimerSNF',
  async ({ requestId, patientId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.TIMER_SNF}/${requestId}?patientId=${patientId}`
    );
    return data;
  }
);
