import { t } from 'i18next';
import { compact, filter, isObject } from 'lodash';
import { GetDownloadPO } from '../../api/services/purchase.service';

export const textColumnFilter = {
  filter: 'agTextColumnFilter',
  display: 'subMenu',
  filterParams: {
    buttons: ['reset']
  }
};

export const setColumnFilter = {
  filter: 'agSetColumnFilter',
  filterParams: {
    buttons: ['reset']
  }
};

export const multiColumnFilter = {
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [textColumnFilter, setColumnFilter]
  }
};

export const formatCurrency = (value) => {
  return value !== '-' && value >= 0 ? `$${value}` : value;
};

export const isThresholdGreaterThanTotal = (threshold, total) => {
  const thresholdValue = parseFloat(threshold);
  const totalValue = parseFloat(total);
  return thresholdValue > totalValue;
};

export const ENDPOINTS = {
  // PURCHASE
  SEARCH_CART_ITEM: 'inventoryservice/api/ShoppingCart/SearchShoppingCartItem',
  ADD_ITEM_TO_CART: 'inventoryservice/api/ShoppingCart/AddPurchaseOrderItemToshoppingcart',
  CART_ITEM_lOCATION_STOCK: 'inventoryservice/api/ShoppingCart/GetItemLocationStockList',
  CART_LOCATION: 'userservice/api/Locations/LocationDropDown',
  PURCHASE_COUNT: 'inventoryservice/api/PurchaseManagement/purchasemanagementcountDetails',
  PURCHASE_LIST: 'inventoryservice/api/PurchaseManagement/purchase',
  PURCHASE_ORDER_LIST: 'inventoryservice/api/PurchaseManagement/AddPurchaseItem',
  PURCHASE_PAYMENT_METHOD: 'inventoryservice/api/Inventory/GetPaymentTypes',
  SHIP_PAYMENT_METHOD: 'inventoryservice/api/Inventory/GetShippingTypes',
  PURCHASE_ORDER_DETAIL: 'inventoryservice/api/PurchaseManagement/ListOfDraftPurchaseOrders',
  PURCHASE_ORDER_DELETE: 'inventoryservice/api/PurchaseManagement/DeleteDraftPurchaseOrders',
  PURCHASE_ORDER_STATUS: 'UserService/api/PoStatus/poStatus',
  RECEIVE_PO_TRANSACTIONI_LOGS:
    'inventoryservice/api/PurchaseManagement/GetPurchaseTransactionLogs',
  PURCHASE_ORDER_UPDATE: 'inventoryservice/api/PurchaseManagement/UpdatePurchaseOrderOrItems',
  UPDATE_RECEIVE_PO_QUANTITY: 'inventoryservice/api/PurchaseManagement/UpdateReceivedQuantity',
  CART_ITEM_UPDATE: 'inventoryservice/api/ShoppingCart/UpdateCartQty',
  SHOPPING_CART_DELETE: 'inventoryservice/api/PurchaseManagement/DeleteShoppingCard',
  APPROVE_TRANSMIT: 'inventoryservice/api/PurchaseManagement/ApproveAndTransmitPO',
  ITEM_LIST_FOR_PURCHASE_ITEM: 'inventoryservice/api/PurchaseManagement/ListOfVendorsForItem',
  SHOPPING_CART_LAST_RUN_CRON_JOB:
    'inventoryservice/api/ShoppingCart/GetShoppingCartLastRunSystemCronJobStatus',
  DOWNLOAD_PO: '/inventoryservice/api/PurchaseManagement/GetDownloadPO',
  SHOPPING_CART_RUN_JOB: 'inventoryservice/api/ShoppingCart/AddItemToShoppingCartManualJob'
};

export const filteredGridData = (gridRef, gridApi) => {
  if (!gridApi) return [];
  const data = gridRef?.current?.getModel().rowsToDisplay.map((node) => node.data) || [];
  return compact(filter(data, isObject));
};

export const handleDownload = async (poID, download, toastError, dispatch) => {
  dispatch(GetDownloadPO(poID))
    .unwrap()
    .then((response) => {
      const disposition = response?.headers['content-disposition'];
      let filename = '';
      if (disposition?.includes('filename')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex?.exec(disposition);
        if (matches?.[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      const uintArray = new Uint8Array(response?.data);
      const blob = new Blob([uintArray]);

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        download(base64String, filename);
      };
      reader.readAsDataURL(blob);
    })
    .catch((error) => {
      if (error) {
        toastError(t('toaster.errors.500Error'), { toastId: 'downloadReportError' });
        return false;
      }
    });
};
