import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDashboardCatalogTiles,
  fetchDashboardCompletedVisitTiles,
  fetchDashboardItemsToOrderTiles,
  fetchDashboardPatientTiles,
  fetchDashboardPurchaseTiles,
  fetchDashboardStockTiles,
  fetchDashboardVisitTiles,
  fetchMfaDetails,
  fetchUserTenant,
  fetchUserTenantLocation,
  updateUserLogoutTime,
  updateUserTenant
} from '../api/services/user.service';

const initialState = {
  isPending: false,
  isLoadedTiles: false,
  isLoadingPatientTiles: false,
  isLoadingPurchaseTiles: false,
  isLoadingVisitTiles: false,
  isLoadingCompletedVisitTiles: false,
  isLoadingItemsOrderTiles: false,
  isLoadingStockTiles: false,
  isLoadingCatalogTiles: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  userTenant: null,
  userMfaDetails: null,
  isGlobalAdmin: false,
  createUserTenantData: null,
  selectedTimeZoneInformation: {},
  selectedLocation: '',
  selectedTenantEMRConfigurationDetails: {},
  selectedTenantConfigurationDetails: {},
  userPermissions: [],
  userTenantLocation: [],
  selectedTenantLocationData: [],
  approverLocations: [],
  cardCount: {
    patientCounts: null,
    visitCount: null,
    purchaseCounts: null,
    catalogCounts: null,
    visit_total_count: null,
    completedVisitCount: null,
    average_completed_visit_count: null,
    last_purchase_order_creation_date: null,
    stock_total_items_count: null,
    last_stock_counted_date: null,
    itemDeficits: null,
    total_catalog_count: null,
    total_Stock_Count: null,
    itemsToOrder: null,
    last_items_order_creation_date: null,
    stockDetails: null
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedLocation(state, action) {
      return { ...state, selectedLocation: action.payload };
    },
    setSelectedTimeZoneInformation(state, action) {
      return { ...state, selectedTimeZoneInformation: action.payload };
    },
    setSelectedTenantEMRConfigurationDetails(state, action) {
      return { ...state, selectedTenantEMRConfigurationDetails: action.payload };
    },
    setSelectedTenantConfigurationDetails(state, action) {
      return { ...state, selectedTenantConfigurationDetails: action.payload };
    },
    setSelectedTenantLocationData(state, action) {
      return { ...state, selectedTenantLocationData: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserTenant.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchUserTenant.fulfilled, (state, action) => {
        const lastTenantId = action?.payload?.userInformation?.last_Tenant_Id;
        const initialTenantId = action?.payload?.tenantInformation?.find(
          (tenant) => tenant.tenant_Id === lastTenantId
        )
          ? lastTenantId
          : action?.payload?.tenantInformation[0]?.tenant_Id;

        const selectedTenantData = action?.payload?.tenantInformation?.find(
          (tenant) => tenant.tenant_Id === initialTenantId
        );

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          userTenant: action.payload,
          isGlobalAdmin: action?.payload?.userInformation?.is_Ga,
          userPermissions: selectedTenantData?.userPermissions?.permissions,
          approverLocations: selectedTenantData?.approverLocations
        };
      })
      .addCase(fetchUserTenant.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updateUserLogoutTime.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(updateUserLogoutTime.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(updateUserLogoutTime.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updateUserTenant.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(updateUserTenant.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          createUserTenantData: action.payload
        };
      })
      .addCase(updateUserTenant.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchDashboardPatientTiles.pending, (state) => {
        return {
          ...state,
          isLoadingPatientTiles: true
        };
      })
      .addCase(fetchDashboardPatientTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingPatientTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            patientCounts: action?.payload
          }
        };
      })
      .addCase(fetchDashboardPatientTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingPatientTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardVisitTiles.pending, (state) => {
        return {
          ...state,
          isLoadingVisitTiles: true
        };
      })
      .addCase(fetchDashboardVisitTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingVisitTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            visitCount: action?.payload?.visitCount,
            visit_total_count: action?.payload?.totalVisitCount
          }
        };
      })
      .addCase(fetchDashboardVisitTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingVisitTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardCompletedVisitTiles.pending, (state) => {
        return {
          ...state,
          isLoadingCompletedVisitTiles: true
        };
      })
      .addCase(fetchDashboardCompletedVisitTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingCompletedVisitTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            completedVisitCount: action?.payload?.visitCount,
            average_completed_visit_count: action?.payload?.totalVisitCount
          }
        };
      })
      .addCase(fetchDashboardCompletedVisitTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingCompletedVisitTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardPurchaseTiles.pending, (state) => {
        return {
          ...state,
          isLoadingPurchaseTiles: true
        };
      })
      .addCase(fetchDashboardPurchaseTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingPurchaseTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            purchaseCounts: action?.payload,
            last_purchase_order_creation_date: action?.payload?.last_purchase_order_creation_date
          }
        };
      })
      .addCase(fetchDashboardPurchaseTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingPurchaseTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardItemsToOrderTiles.pending, (state) => {
        return {
          ...state,
          isLoadingItemsOrderTiles: true
        };
      })
      .addCase(fetchDashboardItemsToOrderTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingItemsOrderTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            itemsToOrder: action?.payload,
            last_items_order_creation_date: action?.payload?.last_modified_date
          }
        };
      })
      .addCase(fetchDashboardItemsToOrderTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingItemsOrderTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardCatalogTiles.pending, (state) => {
        return {
          ...state,
          isLoadingCatalogTiles: true
        };
      })
      .addCase(fetchDashboardCatalogTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingCatalogTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            catalogCounts: action?.payload?.catalogManagementCountResponses,
            total_catalog_count: action?.payload?.total_catalog_count
          }
        };
      })
      .addCase(fetchDashboardCatalogTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingCatalogTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchDashboardStockTiles.pending, (state) => {
        return {
          ...state,
          isLoadingStockTiles: true
        };
      })
      .addCase(fetchDashboardStockTiles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingStockTiles: false,
          isSuccess: true,
          cardCount: {
            ...state?.cardCount,
            stockDetails: action?.payload?.stockCountDetails,
            itemDeficits: action?.payload?.stockTilesDetails,
            stock_total_items_count: action?.payload?.stock_total_items_count,
            last_stock_counted_date: action?.payload?.last_stock_counted_date,
            total_Stock_Count: action?.payload?.total_Stock_Count
          }
        };
      })
      .addCase(fetchDashboardStockTiles.rejected, (state, action) => {
        return {
          ...state,
          isLoadingStockTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchUserTenantLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchUserTenantLocation.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          userTenantLocation: action.payload
        };
      })
      .addCase(fetchUserTenantLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchMfaDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchMfaDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          userMfaDetails: action.payload
        };
      })
      .addCase(fetchMfaDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = userSlice;

export const {
  setSelectedLocation,
  setSelectedTimeZoneInformation,
  setSelectedTenantEMRConfigurationDetails,
  setSelectedTenantConfigurationDetails,
  setSelectedTenantLocationData
} = actions;

export default reducer;
