import { every, isEmpty, isNull, map, mapKeys } from 'lodash';
import moment from 'moment';
import { EMPTY_DATE } from './constants';

export const formatDateInMMDDYYY = (inputDate) => {
  if (!inputDate || inputDate === '0001-01-01T00:00:00') {
    return '';
  }
  const date = moment(inputDate.split('T')[0]);
  return date.format('MM/DD/YYYY');
};

export const formatDateDOS = (inputDate) => {
  if (!inputDate || inputDate === '0001-01-01T00:00:00') {
    return '';
  }

  const dateObj = new Date(inputDate);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Adding 1 since getMonth() is zero-indexed
  const day = String(dateObj.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDate = (dateString) => {
  if (!dateString || dateString?.trim() === '0001-01-01') {
    return '';
  }
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

export const formatDateWithoutTimeInMMDDYYYY = (inputDate) => {
  if (!inputDate || inputDate.trim() === '01/01/0001') {
    return '';
  }
  return inputDate;
};

export const formatPayerDateTimeInMMDDYYYY = (inputDate) => {
  if (!inputDate || inputDate.trim() === '0001-01-01') {
    return '';
  }
  const [year, month, day] = inputDate.split('-');
  return `${month}/${day}/${year}`;
};

export const formatDateInMMDDYYYYhhmm = (inputapptDate) => {
  if (!inputapptDate || inputapptDate === '0001-01-01T00:00:00') {
    return '';
  }
  const date = moment(inputapptDate); // Directly use the full datetime string
  return date.format('MM/DD/YYYY hh:mm A');
};

export const formatObjectDateInMMDDYYY = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return { fromdate: '', todate: '' };
  }

  const formattedFromDate = fromDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const formattedToDate = toDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const fromdate = formatDateInMMDDYYY(formattedFromDate);
  const todate = formatDateInMMDDYYY(formattedToDate);

  return { fromdate, todate };
};

// Function to format date to 'YYYY-MM-DD'
export const formatDateToYYYYMMDD = (date) => {
  const getDate = new Date(date);
  const year = getDate?.getFullYear();
  const month = `${getDate?.getMonth() + 1}`.padStart(2, '0');
  const day = `${getDate?.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const hasNullOrBlankValues = (obj) => {
  return every(obj, (value) => isNull(value) || isEmpty(value));
};

export const formatEditPayerDetails = (patientData) => {
  return patientData?.patientPayerDetails?.map((payer) => {
    const payerNameAndCode = {
      id: payer.tenant_Payer_Id,
      payer_id: payer.code_Id,
      payer_name: payer.payer_First_name
    };
    return {
      payer_id: payer.id || '',
      payerlevel: payer.payer_level || '',
      payernameandcode: payerNameAndCode,
      subMemberID: payer.subscriber_Member_Id || '',
      group: payer.group_Number || '',
      SubRelationship: payer.subscriber_Relationship_To_Patient || '',
      subFirstName: payer.subscriber_First_Name || '',
      subLastName: payer.subscriber_Last_Name || '',
      subDOB: payer.subscriber_Dob === EMPTY_DATE ? null : moment(payer.subscriber_Dob),
      isDeletable: false,
      show: true,
      isPatient: payer.subscriber_Relationship_To_Patient === 'Patient'
    };
  });
};

export const createDiagnosisObject = (item) => ({
  id: item?.diagonis_code_id || item?.id,
  diagnosis_code: item?.diagnosis_code,
  diagnosis_name: item?.diagnosis_name
});

// Transform Items and Bundles

export const transformKeyToLower = (items) => {
  const transformedArray = [];
  const transformItem = (item) => ({
    ...mapKeys(item, (_, key) => key.toLowerCase())
  });
  items.forEach((item) => {
    transformedArray.push(transformItem(item));
  });
  return transformedArray;
};

export const transformItemsAndBundles = (data) => {
  const transformedArray = [];

  const transformItem = (item) => ({
    ...mapKeys(item, (_, key) => key.toLowerCase()),
    isBundled: false
  });

  const transformBundle = (bundle) => ({
    bundle_name: bundle.bundle_Name,
    bundle_count: bundle.bundle_Count,
    bundle_id: bundle.bundle_Id,
    bundle_items: map(bundle.bundle_Items, (obj) => mapKeys(obj, (_, key) => key.toLowerCase())),
    isBundled: true
  });

  data?.items.forEach((item) => {
    transformedArray.push(transformItem(item));
  });

  data?.bundles.forEach((bundle) => {
    const bundleObj = transformBundle(bundle);
    transformedArray.push(bundleObj);
  });

  return transformedArray;
};

const formatVisitSearchOptions = (item, isBundled) => {
  let right_left_id;
  if (item?.right_left_id) {
    right_left_id = item?.right_left_id;
  } else if (item?.laterality === 'Left') {
    right_left_id = 'Left';
  } else if (item?.laterality === 'Right') {
    right_left_id = 'Right';
  } else {
    right_left_id = '';
  }
  return {
    item_id: isBundled ? item?.item_id : item?.id,
    item_name: item?.item_name,
    manufacturer: item?.manufacturer,
    vendor_part_number: item?.vendor_part_number,
    hcpcs: item?.hcpcs,
    customhcpcs: item?.customhcpcs,
    quantity: 1,
    description: item?.item_name,
    price_charge: item?.unit_charge,
    size: item?.size,
    location_name: item?.location_name,
    expanded: true,
    billing_type_id: item?.bill_type_id,
    is_lot_number_required: item?.is_lot_number_required,
    is_prior_auth: item?.is_prior_auth,
    laterality: item?.laterality,
    right_left_id
  };
};

export const formatAutoCompleteOptions = (data, fetchedItems, visitId, isBundled) => {
  const selectedItemDiagnosis =
    (!isEmpty(fetchedItems) && createDiagnosisObject(fetchedItems[0]?.diagnosis)) || null;

  if (isBundled) {
    return data?.map((item) => ({
      ...(visitId && { visit_id: visitId }),
      ...(selectedItemDiagnosis && { diagnosis: selectedItemDiagnosis }),
      ...formatVisitSearchOptions(item, true)
    }));
  } else {
    return {
      ...(visitId && { visit_id: visitId }),
      ...(selectedItemDiagnosis && { diagnosis: selectedItemDiagnosis }),
      ...formatVisitSearchOptions(data, false)
    };
  }
};

export const formattedVisitItems = (data) => {
  let expanded = true;
  return data.map((item) => {
    const selectedItemDiagnosis = createDiagnosisObject(item);

    return {
      ...item,
      visitFieldId: item?.id,
      expanded,
      diagnosis: selectedItemDiagnosis,
      is_prior_auth: item?.is_prior_auth,
      is_lot_number_required: item?.is_lot_number_required
    };
  });
};

export const setKeyWordPayload = (data) =>
  data
    ?.filter(({ isChecked }) => isChecked)
    ?.map((obj) => obj.text)
    ?.join(',');

export const addIsCheckedToObject = (data) =>
  data?.split(',')?.map((val) => ({ text: val, isChecked: false })) || [];
