import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { setToken } from './api/auth';
import { getTermsAndConditions } from './api/services/termsCondition';
import { fetchUserTenant, fetchUserTenantLocation } from './api/services/user.service';
import ErrorBoundary from './components/ErrorBoundry';
import './i18n';
import MainRoutes from './routes/MainRoutes';
import themes from './themes';
import { AG_GRID_LICENCE, MUI_PRO_LICENSE } from './utils/shared/constants';

LicenseManager.setLicenseKey(AG_GRID_LICENCE);
LicenseInfo.setLicenseKey(MUI_PRO_LICENSE);

const App = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const handleRedirectResponse = async () => {
        const authResponse = await instance.handleRedirectPromise();

        if (authResponse) {
          // Access token, ID token, and other information can be extracted from authResponse
          const accessToken = authResponse.accessToken;

          // Use the tokens and user information as needed

          dispatch(fetchUserTenant())
            .unwrap()
            .then((userResponse) => {
              const currentDate = moment().format('YYYY/MM/DD');
              window?.userGuiding?.identify(userResponse?.userInformation?.email, {
                name: userResponse?.userInformation?.user_Name,
                email: userResponse?.userInformation?.email,
                created_at: currentDate,
                isTrial: false
              });
              dispatch(fetchUserTenantLocation());
            });

          let userEmail = accounts[0].username || accounts[0]?.idTokenClaims.email;

          dispatch(getTermsAndConditions(userEmail));
          setToken(accessToken);
        }
      };

      handleRedirectResponse();
    }
  }, [instance, isAuthenticated]);

  const logoutAsSessionTimeout = async () => {
    if (isAuthenticated) {
      await instance.logoutRedirect();
    }
  };

  const onIdle = () => {
    logoutAsSessionTimeout();
  };

  // Utility function to add the UserGuiding script dynamically
  const addUserGuidingScript = () => {
    if (window.userGuiding) return;
    initializeUserGuidingScript();
  };

  const initializeUserGuiding = (g, u, i, d, e, s) => {
    g[e] = g[e] || [];
    let f = u.getElementsByTagName(i)[0];
    let k = u.createElement(i);
    k.async = true;
    k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    let ug = (g[d] = { q: [] });
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    let m = [
      'previewGuide',
      'finishPreview',
      'track',
      'identify',
      'hideChecklist',
      'launchChecklist'
    ];
    for (const item of m) {
      ug[item] = ug.c(item);
    }
  };

  const initializeUserGuidingScript = () => {
    initializeUserGuiding(
      window,
      document,
      'script',
      'userGuiding',
      'userGuidingLayer',
      '2YX81526YZ9ID'
    );
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'uat' ||
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'qa'
    ) {
      addUserGuidingScript();
    }
  }, []);

  useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  });

  return (
    <div className="App">
      <ThemeProvider theme={themes()}>
        <ErrorBoundary>
          <MainRoutes isAuthenticated={isAuthenticated} />
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
};

export default App;
