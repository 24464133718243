import { t } from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { GetDocumentData, UploadProviderSignature } from '../../api/services/settings.service';
import {
  setGetProviderRecord,
  setImageProvider,
  setSaveProviderImage,
  setViewMaintenanceByStatus
} from '../../slices/settings.slice';
import { MAX_PATIENT_FILE_SIZE, ZIPCODE_VALID_REGEX } from '../../utils/shared/constants';
import { allowedImageFileTypes } from '../Patients/utils';

export const settingsTabs = ['Tenant Details', 'Billing', 'Maintenance', 'User Management'];

export const settingsGATabs = [
  'Tenant Details',
  'Billing',
  'Maintenance',
  'User Management',
  'Global'
];

export const tenantTabs = [{ id: 'tenantInfo', label: 'Tenant Info' }];

export const tenantGATabs = [
  { id: 'tenantInfo', label: 'Tenant Info' },
  { id: 'thirdPartyIntegrations', label: '3rd Party Integration' },
  { id: 'subscriptionReports', label: 'Subscription Reports' }
];

export const billingTabs = [
  { id: 'chargeMaster', label: 'Charge Master' },
  { id: 'otherFeeSchedules', label: 'Other Fee Schedules' },
  { id: 'tenantHcpcs', label: 'Tenant HCPCS' }
];

export const billingGATabs = [
  { id: 'chargeMaster', label: 'Charge Master' },
  { id: 'medicareFeeSchdule', label: 'Medicare Fee Schedule' },
  { id: 'otherFeeSchedules', label: 'Other Fee Schedules' },
  { id: 'masterHcpcs', label: 'Master HCPCS' },
  { id: 'tenantHcpcs', label: 'Tenant HCPCS' }
];

export const globalLandingPageTabs = ['Master Payer Mapping', 'Visit Documents'];

export const UserManagementGATabs = ['Users', 'Roles'];

export const UserManagementTabs = ['Users'];

export const GlobalTabs = ['Master Payer Mapping', 'Visit Document'];

export const filterRadioArr = [
  { id: 'Active', description: 'Active' },
  { id: 'Inactive', description: 'Inactive' },
  { id: 'All', description: 'All' }
];

export const dxCodeDropDown = [
  {
    id: '1',
    label: 'Required DX on Manage Items'
  },
  {
    id: '4',
    label: 'Required DX on Finalize Visit'
  },
  {
    id: '2',
    label: 'Neither'
  }
];

export const isNumeric = (value) => {
  return /^\d+$/.test(value);
};

export const isDecimal = (value) => {
  return /^\d{1,7}(\.\d{1,2})?$/.test(value); // Allow up to 7 digits before the decimal and up to 2 digits after the decimal
};

export const isPhoneNumberValid = (phoneNumber) => {
  if (!phoneNumber) return true; // No validation if input is empty
  const numericPhone = phoneNumber.replace(/[^\d]/g, ''); // Remove non-numeric characters
  return numericPhone.length === 10; // Check if length is 10
};

export const isZipCodeValid = (zipCode) => {
  const regex = ZIPCODE_VALID_REGEX;
  if (!zipCode) return true; // No validation if input is empty
  return regex.test(zipCode);
};

export const allowedDocumentFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

export const convertDateToUTC = (date, timeZone) => {
  if (!date || !timeZone) {
    return null; // Return null if necessary information is missing
  }
  const dateformatMMDDYYYY = 'MM/DD/YYYY';

  // Convert string to Date object
  const dateNew = new Date(date);

  // Extract year, month, and day
  const year = dateNew.getFullYear();
  const month = (dateNew.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month starts from 0
  const day = dateNew.getDate().toString().padStart(2, '0');

  // MM/DD/YYYY format
  const dateString = month + '/' + day + '/' + year;

  const localDate = moment.tz(`${dateString} 00:00:00`, `${dateformatMMDDYYYY} HH:mm:ss`, timeZone);

  const utcDate = localDate.utc().format();

  return utcDate;
};

export const setUserFormData = (userData, setValue, convertDateFormat) => {
  setValue('first_Name', userData?.first_Name);
  setValue('email', userData?.email);
  setValue('last_Name', userData?.last_Name);
  setValue('is_Active', userData?.is_Active);
  setValue('Skip_MFA', userData?.skip_MFA);
  setValue('Reset_MFA', userData?.Reset_MFA);
  setValue('phone_number', userData?.phone_number);
  setValue('other_Number', userData?.other_Number);
  setValue(
    'user_access_expiration_date',
    userData?.user_access_expiration_date &&
      convertDateFormat(userData?.user_access_expiration_date)
  );
};

export const yesNoOptions = [
  { id: 'Yes', description: 'Yes' },
  { id: 'No', description: 'No' }
];

export const validateFile = (file, widthConstraint, heightConstraint) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = async () => {
        const { width, height } = image;

        if (width === widthConstraint && height === heightConstraint) {
          resolve(image);
          return;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let scaleFactor = 1;
        if (width > widthConstraint || height > heightConstraint) {
          scaleFactor = Math.min(widthConstraint / width, heightConstraint / height);
        }

        canvas.width = width * scaleFactor;
        canvas.height = height * scaleFactor;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        const blob = await canvasToBlobAsync(canvas, file.type);

        const resizedImage = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now()
        });

        resolve(resizedImage);
      };

      image.src = event.target.result;
    };

    reader.readAsDataURL(file);
  });
};

function canvasToBlobAsync(canvas, type) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve, type);
  });
}

export const logoValidation = (file, setErrorMessage) => {
  const isValidFileType = allowedImageFileTypes.includes(file.type);

  if (!isValidFileType) {
    const errorMessage = t('addPatient.validationMessage.invalidFileType');
    setErrorMessage(errorMessage);
    return { code: 'invalid-file-type', message: errorMessage };
  }

  if (file.size > MAX_PATIENT_FILE_SIZE) {
    const errorMessage = t('settings.global.visitDocuments.pdfSizeError');
    setErrorMessage(errorMessage);
    return { code: 'size-too-large', message: errorMessage };
  }
  setErrorMessage('');
  return null;
};

export const getDeletedRole = (formValues, userData) => {
  const selectedRoleIds = formValues?.Roles?.map((s) => s.id) || [];
  return userData?.roles?.filter((d) => {
    return !selectedRoleIds.includes(d.id);
  });
};

export const getDeletedLocation = (formValues, userData) => {
  const selectedLocationIds = formValues?.Locations?.map((s) => s.id) || [];
  return userData?.locations?.filter((d) => {
    return !selectedLocationIds.includes(d.id);
  });
};

export const providerRoles = [
  { id: 'Rendering', name: 'Rendering' },
  { id: 'Ordering', name: 'Ordering' },
  { id: 'Supervising', name: 'Supervising' }
];

export const validateFields = async (
  trigger,
  enableSignatureCapture,
  isProviderImagePresent,
  t,
  toastError
) => {
  const isValid = await trigger();
  if (!isValid) return false;

  if (enableSignatureCapture && !isProviderImagePresent) {
    toastError(
      t(
        'settings.settingsLandingPage.maintenance.provider.addNewProvider.validationMsg.SignatureRequiredMsg'
      )
    );
    return false;
  }
  return true;
};

export const createPayload = (formValues, GetProviderRecord) => {
  const {
    firstName,
    lastName,
    credentials,
    speciality,
    enableSignatureCapture,
    providerType,
    visitLocation,
    npi,
    externalId,
    enableActiveProvider
  } = formValues || {};
  return {
    first_name: firstName,
    last_name: lastName,
    credentials: credentials,
    speciality: speciality || null,
    signature_capture: enableSignatureCapture || false,
    provider_type: providerType?.map((type) => type?.id).join(','),
    visit_location: visitLocation?.map((location) => location?.id) || [],
    npi,
    external_id: externalId,
    active_provider: enableActiveProvider || false,
    ...(GetProviderRecord?.id && { id: GetProviderRecord?.id })
  };
};

export const handleSuccessResponse = (
  response,
  imageUrl,
  dispatch,
  toastError,
  enableSignatureCapture,
  previousImageUrl,
  signedImage
) => {
  if (
    (response?.data?.id && imageUrl && enableSignatureCapture) ||
    (response?.data?.id && previousImageUrl && !enableSignatureCapture)
  ) {
    dispatch(UploadProviderSignature({ ...signedImage, userId: response?.data?.id }))
      .unwrap()
      .catch(() => {
        toastError(t('toaster.errors.500Error'), {
          toastId: 'signatureUploadErrorMsg'
        });
      });
  }
};

export const fetchEditProviders = (GetProviderRecord, setValue, visitLocationsDetails) => {
  if (!isEmpty(GetProviderRecord)) {
    const fieldsToSet = {
      firstName: GetProviderRecord?.first_name,
      lastName: GetProviderRecord?.last_name,
      credentials: GetProviderRecord?.credentials,
      speciality: GetProviderRecord?.speciality,
      npi: GetProviderRecord?.npi,
      externalId: GetProviderRecord?.external_id,
      ProviderType: GetProviderRecord?.provider_type,
      visitLocation: GetProviderRecord?.visit_location,
      enableSignatureCapture: GetProviderRecord?.signature_capture,
      enableActiveProvider: GetProviderRecord?.active_provider
    };

    Object.entries(fieldsToSet).forEach(([field, value]) => {
      setValue(field, value);
    });
    if (GetProviderRecord?.provider_type) {
      const providerTypeArray = GetProviderRecord?.provider_type.split(',').map((type) => {
        const trimmedType = type.trim();
        return providerRoles.find((role) => role.name === trimmedType) || {};
      });
      setValue('providerType', providerTypeArray);
    }

    if (Array.isArray(GetProviderRecord?.visit_location)) {
      const visitLocationArray = GetProviderRecord?.visit_location?.map((locationId) => {
        return visitLocationsDetails?.find((loc) => loc?.id === locationId) || {};
      });
      setValue('visitLocation', visitLocationArray);
    }
  }
};
export const updateStateAndClose = (
  dispatch,
  t,
  viewMaintenanceByStatus,
  toastSuccess,
  onClose
) => {
  if (viewMaintenanceByStatus.id) {
    toastSuccess(
      t(
        'settings.settingsLandingPage.maintenance.provider.addNewProvider.updateProviderSuccessMsg'
      ),
      {
        toastId: 'providerUpdatedMsg'
      }
    );
  } else {
    toastSuccess(
      t(
        'settings.settingsLandingPage.maintenance.provider.addNewProvider.addNewProviderSuccessMsg'
      ),
      {
        toastId: 'providerAddeddMsg'
      }
    );
  }

  dispatch(
    setViewMaintenanceByStatus({
      id: null,
      openPayerDialog: false,
      openLocationsDialog: false,
      openProvidersDialog: false
    })
  );

  dispatch(setGetProviderRecord({ providerRecord: null }));
  dispatch(setImageProvider({ imageProvider: null }));
  onClose();
};

export const booleanFilterParams = {
  textCustomComparator: (filter, value, filterText) => {
    const displayValue = value === true ? 'Yes' : 'No';
    return displayValue.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
  }
};

export const fetchAndSetImageData = (
  id,
  module,
  tenantId,
  setDataFunction,
  dispatch,
  isReduxDispatch = false
) => {
  dispatch(GetDocumentData({ id, module, tenantId }))
    .unwrap()
    .then((response) => {
      const uintArray = new Uint8Array(response?.data);
      const blob = new Blob([uintArray]);
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        if (isReduxDispatch) {
          dispatch(setDataFunction(base64String));
          dispatch(setSaveProviderImage(base64String));
        } else {
          setDataFunction(base64String);
        }
      };

      reader.readAsDataURL(blob);
    })
    .catch(() => {
      if (isReduxDispatch) {
        dispatch(setDataFunction(null));
        dispatch(setSaveProviderImage(null));
      } else {
        setDataFunction(null);
      }
    });
};

export const ENDPOINTS = {
  // Admin Settings
  GET_BILLING_CHARGE_MASTER: 'userservice/api/admin/ChargeMaster/GetBillingChargeMaster',
  ADD_RECORD_IN_CHARGE_MASTER: 'userservice/api/admin/ChargeMaster/AddRecordInChargeMaster',
  UPDATE_CHARGE_MASTER: 'userservice/api/admin/ChargeMaster/UpdateChargeMaster',
  ADD_ROLE_WITH_PERMISSION: '/UserService/api/admin/RolePermission/AddRoleWithPermissions',
  GET_ALL_ROLE_PERMISSIONS: '/UserService/api/admin/RolePermission/GetAllRolePermissions',
  ROLE_GET_ALL_PERMISSION: 'UserService/api/admin/Permission/GetAllPermissions',
  UPDATE_ROLE_PERMISSION: '/UserService/api/admin/RolePermission/UpdateRolePermission',
  DELETE_ROLE_PERMISSION: '/UserService/api/admin/Role/DeleteRole',
  DELETE_CHARGE_MASTER: 'userservice/api/admin/ChargeMaster/DeleteChargeMaster',
  GET_PAYERS: 'userservice/api/admin/Payer/GetPayers',
  GET_PAYERS_TYPES: 'userservice/api/admin/Payer/GetPayerTypes',
  ADD_PAYERS_DETAILS: 'userservice/api/admin/Payer/AddPayersDetails',
  UPDATE_PAYERS: 'userservice/api/admin/Payer/UpdatePayers',
  GET_PAYER_BY_ID: 'userservice/api/admin/Payer/GetPayerById',
  GET_FEE_SCHEDULE_LIST: 'userservice/api/admin/FeeSchedule/GetFeeScheduleList',
  GET_LOCATIONS_DETAILS_BY_FILTER:
    'userservice/api/admin/LocationsMaintenance/GetLocationsDetailsByFilter',
  GET_LOCATION_DETAILS_BY_ID: 'userservice/api/admin/LocationsMaintenance/GetLocationDetailsById',
  INSERT_OR_UPDATE_LOCATIONS_DETAILS:
    'userservice/api/admin/LocationsMaintenance/InsertOrUpdateLocationDetails',
  UPLOAD_LOCATION_LOGO: 'userservice/api/admin/LocationsMaintenance/UploadLocationLogo',
  SETTINGS_MASTER_HCPCS_ITEM_CATEGORY: 'userservice/api/Report/GetItemCategories',
  SETTINGS_MASTER_TENANT_HCPCS: 'userservice/api/admin/HcpcsAdmin/GetAllHcpcs',
  SETTINGS_ADD_UPDATE_HCPCS: 'userservice/api/admin/HcpcsAdmin/AddOrUpdateHcpcs',
  GET_FEE_SCHEDULE_DETAILS: 'userservice/api/admin/FeeSchedule/GetFeeScheduleDetails',
  ADD_TENANT_FEE_SCHEDULES: 'userservice/api/admin/FeeSchedule/AddTenantFeeSchedules',
  UPDATE_TENANT_FEE_SCHEDULES: 'userservice/api/admin/FeeSchedule/UpdateTenantFeeSchedules',
  GET_ALL_USERS_DATA: 'UserService/api/User/UserList',
  GET_ALL_ROLE: 'UserService/api/admin/Role/GetAllRoles',
  GET_ALL_LOCATION: 'userservice/api/Report/GetLocationList',
  ADD_UPDATE_USER: 'UserService/api/User/AddOrEditUser',
  GET_USERS_DATA_ID: 'UserService/api/User/GetUserById/',
  INVITE_USER: 'UserService/api/User/InviteUser',
  GET_VISIT_DOCUMENTS: 'userservice/api/admin/MasterDocument/GetVisitDocuments',
  GET_VISIT_DOCUMENTS_TYPE: 'userservice/api/admin/MasterDocument/GetMasterDocumentType',
  VISIT_LOCATION: 'userservice/api/admin/ProvidersMaintenance/GetVisitLocations',
  GET_PROVIDER_DETAILS: 'userservice/api/admin/ProvidersMaintenance/GetProvidersDetailsByFilter',
  GET_SPECIALITY: 'userservice/api/admin/ProvidersMaintenance/GetSepcialty',
  GET_PROVIDER_DETAILS_BY_ID: 'userservice/api/admin/ProvidersMaintenance/GetProviderDetailsById',
  ADD_PROVIDER_DATA: 'userservice/api/admin/ProvidersMaintenance/InsertOrUpdateProviderDetails',
  UPLOAD_PROVIDER: 'userservice/api/admin/ProvidersMaintenance/UploadProviderSignature',
  GET_VISIT_DOCUMENTS_PAYER_TYPE: 'userservice/api/admin/Payer/GetPayerTypes',
  UPLOAD_MASTER_DOCUMENT: 'userservice/api/admin/MasterDocument/AddUpdateRecordInMasterDocument',
  UPLOAD_VISIT_DOCUMENT: 'userservice/api/admin/MasterDocument/UploadDocument',
  GET_VISIT_DOCUMENT_BY_ID: 'userservice/api/admin/MasterDocument/GetVisitDocumentByID',
  ACTIVATE_INACTIVATE_USER: 'userservice/api/User/ActiveInactiveUsers',
  GET_EMR_LIST: 'userservice/api/admin/ThirdPartyIntegration/GetEMRList',
  GET_THIRD_PARTY_INTEGRATION:
    'userservice/api/admin/ThirdPartyIntegration/GetThirdPartyIntegration',
  GET_IN_OUT_BOUND_LIST:
    'userservice/api/admin/ThirdPartyIntegration/GetInboundOrOutboundTypesList',
  UPDATE_THIRD_PARTY_INTEGRATION:
    'userservice/api/admin/ThirdPartyIntegration/UpdateThirdPartyIntegration',
  USER_RESET_PASSWORD: 'UserService/api/admin/AzureAction/ResetPassword',
  BULK_RESET_MFA: 'UserService/api/admin/AzureAction/UpdateMFA',
  GET_ELIGIBILITY_PAYERS: 'userservice/api/admin/Payer/GetEligibilityPayer',
  GET_DOCUMENT_DATA: 'UserService/api/DocumentView/GetDocument',
  UPLOAD_FITTER_SIGNATURE: 'UserService/api/User/UploadUserFitterSignature'
};
