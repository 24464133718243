import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Visits/utils';
import apiClient from '../axios';

export const fetchVisitCounts = createAsyncThunk('visits/fetchVisitCounts', async () => {
  const { data } = await apiClient.get(ENDPOINTS.VISIT_COUNT);
  return data;
});

export const fetchAllVisitStatus = createAsyncThunk('visits/fetchVisitStatus', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.ALL_VISIT_STATUS}?isCustomVisitStatus=true`);
  return data;
});

export const fetchVisitList = createAsyncThunk('visits/fetchVisitList', async (payloadData) => {
  const { data } = await apiClient.post(ENDPOINTS.VISIT_LIST, payloadData);
  return data;
});

export const fetchCustomFitDataByAI = createAsyncThunk(
  'visits/fetchCustomFitDataByAI',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.GENERATE_CUSTOM_DESCRIPTION, payloadData);
    return data;
  }
);

export const fetchTenantAIkeyword = async (itemID) => {
  const { data } = await apiClient.get(`${ENDPOINTS.TENANT_AI_KEYWORD}?itemId=${itemID}`);
  return data || {};
};

export const updateItemToCart = createAsyncThunk(
  'visits/updateItemToCart',
  async (cartData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.UPDATE_ITEM_TO_CART, cartData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error?.response?.data?.errorMessage : '');
    }
  }
);
