import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  AddPayersDetails,
  AddProviderDetails,
  AddRecordInChargeMaster,
  AddRoleWithPermissions,
  AddTenantFeeSchedules,
  AddUpdateRecordHCPCS,
  GetAllPermissions,
  GetAllRolePermissions,
  GetBillingChargeMaster,
  GetDocumentData,
  GetEMRList,
  GetEligibilityPayers,
  GetFeeScheduleDetails,
  GetFeeScheduleList,
  GetInBound,
  GetItemCategories,
  GetLocationDetailsById,
  GetLocationsDetailsByFilter,
  GetMasterTenantHCPCS,
  GetOutBound,
  GetPayerById,
  GetPayerTypes,
  GetPayers,
  GetProviderDetails,
  GetProviderDetailsByID,
  GetSpeciality,
  GetThirdPartyIntegration,
  GetVisitDocument,
  GetVisitDocumentByID,
  GetVisitDocumentTypes,
  GetVisitDocumentsPayerType,
  GetVisitLocation,
  InsertOrUpdateLocationDetails,
  InviteUser,
  UpdateActivateInactivateUser,
  UpdateChargeMaster,
  UpdatePayers,
  UpdateRolePermission,
  UpdateTenantFeeSchedules,
  UpdateThirdPartyIntegration,
  UploadLocationLogo,
  UploadMasterDocuments,
  UploadProviderSignature,
  addUpdateUser,
  fetchLocationList,
  fetchRoleList,
  fetchSubscriptionReports,
  fetchUserDataByID,
  fetchUserList,
  fetchVisitDocumentsBillingTypes,
  uploadFitterSignature,
  uploadVisitDocument,
  userResetMFA,
  userResetPassword
} from '../api/services/settings.service';

const initialState = {
  isPending: false,
  isTenantHCPCSPending: false,
  isthirdPartyDataPending: false,
  isError: false,
  errorMessage: '',
  getBillingChargeMaster: null,
  addNewRecord: false,
  updateRecord: false,
  addNewRolePayload: [],
  allRolePermissions: [],
  allPermissions: [],
  payerDetailsById: [],
  viewMaintenanceByStatus: {
    id: null,
    openPayerDialog: false,
    openLocationsDialog: false,
    openProvidersDialog: false
  },
  viewBillingByStatus: {
    id: null,
    openFeeScheduleDialog: false
  },
  auditHistoryDetails: [],
  isPayerUpdated: false,
  isPayerAdded: false,
  searchCatalogHCPCS: null,
  getLocationsDetailsByFilter: null,
  getLocationDetailsById: [],
  locationId: null,
  addNewLocation: false,
  updateNewLocation: false,
  GetItemCategoriesRecord: [],
  GetHCPCSRecord: null,
  addUpdateHcpcsRecord: [],
  getFeeScheduleList: null,
  usersList: null,
  roleList: [],
  locationList: [],
  userFilterValue: 'Active',
  userModal: {
    isOpen: false,
    isAddUser: false,
    isEditUser: false,
    userData: {},
    title: ''
  },
  subscriptionReports: null,
  getVisitDocumentsData: [],
  isVisitDocumentAdded: false,
  isVisitDocumentUpdated: false,
  isVisitDoc: {
    open: false,
    docId: null,
    isEditing: false
  },
  documentTypes: [],
  openAddRoleModal: false,
  getFeeScheduleDetails: [],
  addFeeScheduleDetails: false,
  updateFeeScheduleDetails: false,
  isGetFeeScheduleDetails: false,
  isGetPayersPending: false,
  isUploadLocationLogo: false,
  isPendingInviteUser: false,
  getLocationsDetailsByFilterPending: false,
  visitLocationsDetails: [],
  providerDetails: null,
  updateProviderTypes: [],
  specialityData: [],
  GetProviderRecord: [],
  insertProviderDetails: [],
  providerSourceData: [],
  addNewProvider: false,
  isProviderImagePresent: false,
  imageProvider: '',
  thirdPartyIntegrationData: [],
  EMRList: [],
  inBoundData: [],
  outBoundData: [],
  payerTypeData: [],
  visitDocumentData: [],
  billingTypeData: [],
  isGetPayerTypesPending: false,
  getPayersTypes: [],
  isAuditHistoryDetailsPending: false,
  uploadVisitDocumentPending: false,
  locationValue: 'Active',
  payerValue: 'Active',
  isResetPassword: false,
  isResetMFA: false,
  isMasterRecordAdded: false,
  checkedStockLocation: false,
  selectedUserLocation: '',
  enableActivePayer: true,
  minDate: null,
  disableDate: true,
  disableSave: true,
  eligibilityPayers: [],
  isMasterPayerUpdated: false,
  documentDataList: [],
  saveProviderImage: '',
  uploadFitterSignaturePending: false,
  isEligibilityPayers: true
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBillingChargeMaster: (state, action) => {
      return { ...state, getBillingChargeMaster: action.payload };
    },
    setViewMaintenanceByStatus: (state, action) => {
      return { ...state, viewMaintenanceByStatus: action.payload };
    },
    setViewBillingByStatus: (state, action) => {
      return { ...state, viewBillingByStatus: action.payload };
    },
    setGetPayerById: (state, action) => {
      return {
        ...state,
        payerDetailsById: action.payload.payerDetailsById,
        auditHistoryDetails: action.payload.auditHistoryDetails
      };
    },
    setIsVisitDoc: (state, action) => {
      return { ...state, isVisitDoc: action.payload };
    },
    setGetLocationDetailsById: (state, action) => {
      return {
        ...state,
        getLocationDetailsById: action.payload.getLocationDetailsById
      };
    },
    setGetHCPCSRecord: (state, action) => {
      return { ...state, GetHCPCSRecord: action.payload };
    },
    setOpenAddRoleModal: (state, action) => {
      return { ...state, openAddRoleModal: action.payload };
    },
    setGetFeeScheduleDetails: (state, action) => {
      return { ...state, getFeeScheduleDetails: action.payload };
    },
    setUserModalData: (state, action) => {
      return {
        ...state,
        userModal: action.payload
      };
    },
    setUserFilterValue: (state, action) => {
      return {
        ...state,
        userFilterValue: action.payload
      };
    },
    setProviderDetails: (state, action) => {
      return { ...state, providerDetails: action.payload };
    },
    setGetProviderRecord: (state, action) => {
      return { ...state, GetProviderRecord: action.payload };
    },
    setProviderSourceData: (state, action) => {
      return { ...state, providerSourceData: action.payload };
    },
    setIsProviderImagePresent: (state, action) => {
      return { ...state, isProviderImagePresent: action.payload };
    },
    setAddNewProvider: (state, action) => {
      return { ...state, addNewProvider: action.payload };
    },
    setImageProvider: (state, action) => {
      return { ...state, imageProvider: action.payload };
    },
    setSaveProviderImage: (state, action) => {
      return { ...state, saveProviderImage: action.payload };
    },
    setLocationValue: (state, action) => {
      return { ...state, locationValue: action.payload };
    },
    setPayerValue: (state, action) => {
      return { ...state, payerValue: action.payload };
    },
    setIsMasterRecordAdded: (state, action) => {
      return { ...state, isMasterRecordAdded: action.payload };
    },
    setCheckedStockLocation: (state, action) => {
      return { ...state, checkedStockLocation: action.payload };
    },
    setSelectedUserLocation: (state, action) => {
      return { ...state, selectedUserLocation: action.payload };
    },
    setEnableActivePayer: (state, action) => {
      return { ...state, enableActivePayer: action.payload };
    },
    setMinDate: (state, action) => {
      return { ...state, minDate: action.payload };
    },
    setDisableDate: (state, action) => {
      return { ...state, disableDate: action.payload };
    },
    setDisableSave: (state, action) => {
      return { ...state, disableSave: action.payload };
    },
    setSubscriptionCellDescModal: (state, action) => {
      return { ...state, subscriptionCellDescModal: action.payload };
    },
    setIsMasterPayerUpdated: (state, action) => {
      return { ...state, isMasterPayerUpdated: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(GetBillingChargeMaster.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetBillingChargeMaster.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          getBillingChargeMaster: action?.payload?.data?.chargeMasterList,
          addNewRecord: false,
          updateRecord: false
        };
      })
      .addCase(GetBillingChargeMaster.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(AddRecordInChargeMaster.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddRecordInChargeMaster.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          addNewRecord: true
        };
      })
      .addCase(AddRecordInChargeMaster.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateChargeMaster.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdateChargeMaster.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          updateRecord: true
        };
      })
      .addCase(UpdateChargeMaster.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetPayers.pending, (state) => {
        return {
          ...state,
          isGetPayersPending: true
        };
      })
      .addCase(GetPayers.fulfilled, (state, action) => {
        const transformedPayerData = action?.payload?.data?.map((record) => ({
          ...record,
          status: record.isActive ? 'Active' : 'Inactive'
        }));
        return {
          ...state,
          isGetPayersPending: false,
          getPayers: transformedPayerData
        };
      })
      .addCase(GetPayers.rejected, (state, action) => {
        return {
          ...state,
          isGetPayerTypesPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetPayerTypes.pending, (state) => {
        return {
          ...state,
          isGetPayerTypesPending: true
        };
      })
      .addCase(GetPayerTypes.fulfilled, (state, action) => {
        return {
          ...state,
          isGetPayerTypesPending: false,
          getPayersTypes: action?.payload?.data
        };
      })
      .addCase(GetPayerTypes.rejected, (state, action) => {
        return {
          ...state,
          isGetPayersPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(AddPayersDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddPayersDetails.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isPayerAdded: true
        };
      })
      .addCase(AddPayersDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdatePayers.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdatePayers.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isPayerUpdated: true
        };
      })
      .addCase(UpdatePayers.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetPayerById.pending, (state) => {
        return {
          ...state,
          isAuditHistoryDetailsPending: true
        };
      })
      .addCase(GetPayerById.fulfilled, (state, action) => {
        return {
          ...state,
          isAuditHistoryDetailsPending: false,
          payerDetailsById: action?.payload?.data?.payer,
          auditHistoryDetails: action?.payload?.data?.auditHistory
        };
      })
      .addCase(GetPayerById.rejected, (state, action) => {
        return {
          ...state,
          isAuditHistoryDetailsPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(GetLocationsDetailsByFilter.pending, (state) => {
        return {
          ...state,
          getLocationsDetailsByFilterPending: true
        };
      })

      .addCase(GetLocationsDetailsByFilter.fulfilled, (state, action) => {
        const transformedLocationData = action?.payload?.data?.data?.map((record) => ({
          ...record,
          status: record.is_Active ? 'Active' : 'Inactive'
        }));
        return {
          ...state,
          getLocationsDetailsByFilterPending: false,
          getLocationsDetailsByFilter: transformedLocationData
        };
      })
      .addCase(GetLocationsDetailsByFilter.rejected, (state, action) => {
        return {
          ...state,
          getLocationsDetailsByFilterPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(InsertOrUpdateLocationDetails.pending, (state) => {
        return {
          ...state,
          isPending: true,
          addNewLocation: false
        };
      })
      .addCase(InsertOrUpdateLocationDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          locationId: action?.payload?.data?.data?.id,
          addNewLocation: true
        };
      })
      .addCase(InsertOrUpdateLocationDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          addNewLocation: false
        };
      })
      .addCase(GetLocationDetailsById.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetLocationDetailsById.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          getLocationDetailsById: action?.payload?.data?.data
        };
      })
      .addCase(GetLocationDetailsById.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UploadLocationLogo.pending, (state) => {
        return {
          ...state,
          isUploadLocationLogo: true
        };
      })
      .addCase(UploadLocationLogo.fulfilled, (state) => {
        return {
          ...state,
          isUploadLocationLogo: false
        };
      })
      .addCase(UploadLocationLogo.rejected, (state, action) => {
        return {
          ...state,
          isUploadLocationLogo: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetItemCategories.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetItemCategories.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          GetItemCategoriesRecord: action?.payload?.data
        };
      })
      .addCase(GetItemCategories.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          GetItemCategoriesRecord: []
        };
      })
      .addCase(GetMasterTenantHCPCS.pending, (state) => {
        return {
          ...state,
          isTenantHCPCSPending: true
        };
      })
      .addCase(GetMasterTenantHCPCS.fulfilled, (state, action) => {
        return {
          ...state,
          isTenantHCPCSPending: false,
          GetHCPCSRecord: action?.payload
        };
      })
      .addCase(GetMasterTenantHCPCS.rejected, (state, action) => {
        return {
          ...state,
          isTenantHCPCSPending: false,
          isError: true,
          errorMessage: action.error.message,
          GetHCPCSRecord: null
        };
      })
      .addCase(AddUpdateRecordHCPCS.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddUpdateRecordHCPCS.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          addUpdateHcpcsRecord: action?.payload
        };
      })
      .addCase(AddUpdateRecordHCPCS.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetFeeScheduleList.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetFeeScheduleList.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          getFeeScheduleList: action?.payload?.data
        };
      })
      .addCase(GetFeeScheduleList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchUserList.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isError: false,
          usersList: null,
          userModal: {
            isOpen: false,
            isAddUser: false,
            isEditUser: false,
            userData: {}
          }
        };
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          usersList: action?.payload
        };
      })
      .addCase(fetchUserList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchRoleList.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isError: false,
          roleList: []
        };
      })
      .addCase(fetchRoleList.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          roleList: action?.payload
        };
      })
      .addCase(fetchRoleList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchLocationList.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isError: false,
          locationList: []
        };
      })
      .addCase(fetchLocationList.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          locationList: action?.payload
        };
      })
      .addCase(fetchLocationList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(addUpdateUser.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isError: false
        };
      })
      .addCase(addUpdateUser.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(addUpdateUser.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchUserDataByID.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isError: false
        };
      })
      .addCase(fetchUserDataByID.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          userModal: {
            isOpen: true,
            title: 'Edit User',
            isAddUser: false,
            isEditUser: true,
            userData: {
              ...action?.payload?.userData,
              roles: action?.payload?.roles?.map((r) => {
                let rr = { ...r };
                rr.roleName = rr.role_Name;
                return rr;
              }),
              locations: action?.payload?.locations
            }
          }
        };
      })
      .addCase(fetchUserDataByID.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchSubscriptionReports.pending, (state) => {
        return {
          ...state,
          isPending: true,
          subscriptionReports: null
        };
      })
      .addCase(fetchSubscriptionReports.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          subscriptionReports: action?.payload
        };
      })
      .addCase(fetchSubscriptionReports.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetVisitDocument.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetVisitDocument.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          getVisitDocumentsData: action?.payload
        };
      })
      .addCase(GetVisitDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetFeeScheduleDetails.pending, (state) => {
        return {
          ...state,
          isGetFeeScheduleDetails: true,
          getFeeScheduleDetails: []
        };
      })
      .addCase(GetFeeScheduleDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isGetFeeScheduleDetails: false,
          getFeeScheduleDetails: action?.payload?.data
        };
      })
      .addCase(GetFeeScheduleDetails.rejected, (state, action) => {
        return {
          ...state,
          isGetFeeScheduleDetails: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(AddTenantFeeSchedules.pending, (state) => {
        return {
          ...state,
          isPending: true,
          addFeeScheduleDetails: false
        };
      })
      .addCase(AddTenantFeeSchedules.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          addFeeScheduleDetails: true
        };
      })
      .addCase(AddTenantFeeSchedules.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetVisitDocumentTypes.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetVisitDocumentTypes.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          documentTypes: action?.payload
        };
      })
      .addCase(GetVisitDocumentTypes.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateTenantFeeSchedules.pending, (state) => {
        return {
          ...state,
          isPending: true,
          updateFeeScheduleDetails: false
        };
      })
      .addCase(UpdateTenantFeeSchedules.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          updateFeeScheduleDetails: true
        };
      })
      .addCase(UpdateTenantFeeSchedules.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetVisitLocation.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetVisitLocation.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          visitLocationsDetails: action?.payload?.data?.data
        };
      })
      .addCase(GetVisitLocation.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetProviderDetails.pending, (state) => {
        return {
          ...state,
          isProviderPending: true
        };
      })
      .addCase(GetProviderDetails.fulfilled, (state, action) => {
        const transformedData = action?.payload?.data?.map((record) => ({
          ...record,
          status: record.is_active ? 'Active' : 'Inactive'
        }));
        return {
          ...state,
          isProviderPending: false,
          providerDetails: transformedData
        };
      })
      .addCase(GetProviderDetails.rejected, (state, action) => {
        return {
          ...state,
          isProviderPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetSpeciality.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          specialityData: action?.payload?.data?.data
        };
      })
      .addCase(GetSpeciality.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetProviderDetailsByID.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetProviderDetailsByID.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          GetProviderRecord: action?.payload?.data
        };
      })
      .addCase(GetProviderDetailsByID.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          GetProviderRecord: []
        };
      })
      .addCase(AddProviderDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddProviderDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          insertProviderDetails: action?.payload,
          addNewProvider: true
        };
      })
      .addCase(AddProviderDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          addNewProvider: false
        };
      })
      .addCase(UploadProviderSignature.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UploadProviderSignature.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(UploadProviderSignature.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(AddRoleWithPermissions.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(AddRoleWithPermissions.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          addNewRolePayload: action.payload
        };
      })
      .addCase(AddRoleWithPermissions.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetAllRolePermissions.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetAllRolePermissions.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          allRolePermissions: action.payload
        };
      })
      .addCase(GetAllRolePermissions.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetAllPermissions.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetAllPermissions.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          allPermissions: action.payload
        };
      })
      .addCase(GetAllPermissions.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateRolePermission.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdateRolePermission.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(UpdateRolePermission.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateActivateInactivateUser.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UpdateActivateInactivateUser.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(UpdateActivateInactivateUser.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetVisitDocumentsPayerType.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetVisitDocumentsPayerType.fulfilled, (state, action) => {
        const modData = action?.payload?.data?.map((item) => ({
          description: item?.description,
          id: item?.description
        }));
        return {
          ...state,
          isPending: false,
          payerTypeData: modData
        };
      })
      .addCase(GetVisitDocumentsPayerType.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(UploadMasterDocuments.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(UploadMasterDocuments.fulfilled, (state) => {
        return {
          ...state,
          isPending: false
        };
      })
      .addCase(UploadMasterDocuments.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(GetVisitDocumentByID.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetVisitDocumentByID.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          visitDocumentData: action?.payload?.data
        };
      })
      .addCase(GetVisitDocumentByID.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchVisitDocumentsBillingTypes.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchVisitDocumentsBillingTypes.fulfilled, (state, action) => {
        const modData = action?.payload?.billingTypes?.map((item) => ({
          description: item?.description,
          id: item?.description
        }));
        return {
          ...state,
          isPending: false,
          billingTypeData: modData
        };
      })
      .addCase(fetchVisitDocumentsBillingTypes.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetThirdPartyIntegration.pending, (state) => {
        return {
          ...state,
          isthirdPartyDataPending: true
        };
      })
      .addCase(GetThirdPartyIntegration.fulfilled, (state, action) => {
        return {
          ...state,
          isthirdPartyDataPending: false,
          thirdPartyIntegrationData: {
            ...action.payload,
            emr:
              action.payload.emr !== null
                ? {
                    emr_keyword: action.payload.emr,
                    description: action.payload.emr
                  }
                : null,
            pm:
              action.payload.pm !== null
                ? {
                    emr_keyword: action.payload.pm,
                    description: action.payload.pm
                  }
                : null,
            inbound_Data_Type: !isEmpty(action.payload?.inbound_Data_Type)
              ? action.payload?.inbound_Data_Type?.split(',')?.map((item) => {
                  return {
                    description: item
                  };
                })
              : [],
            outbound_Data_Type: !isEmpty(action.payload?.outbound_Data_Type)
              ? action.payload?.outbound_Data_Type?.split(',')?.map((item) => {
                  return {
                    description: item
                  };
                })
              : [],
            order_Forms_To_Send: action.payload?.order_Forms_To_Send?.split(',')?.map((item) => {
              return {
                keyword: item
              };
            })
          }
        };
      })
      .addCase(GetThirdPartyIntegration.rejected, (state, action) => {
        return {
          ...state,
          isthirdPartyDataPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(GetEMRList.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetEMRList.fulfilled, (state, action) => {
        const modData = action?.payload?.map((item) => ({
          emr_keyword: item?.description,
          description: item?.description
        }));
        return {
          ...state,
          isPending: false,
          EMRList: modData
        };
      })
      .addCase(GetEMRList.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(GetInBound.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetInBound.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          inBoundData: action.payload
        };
      })
      .addCase(GetInBound.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(GetOutBound.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetOutBound.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          outBoundData: action.payload
        };
      })
      .addCase(GetOutBound.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(UpdateThirdPartyIntegration.pending, (state) => {
        return {
          ...state,
          isthirdPartyDataPending: true
        };
      })
      .addCase(UpdateThirdPartyIntegration.fulfilled, (state) => {
        return {
          ...state,
          isthirdPartyDataPending: true
        };
      })
      .addCase(UpdateThirdPartyIntegration.rejected, (state, action) => {
        return {
          ...state,
          isthirdPartyDataPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(uploadVisitDocument.pending, (state) => {
        return {
          ...state,
          uploadVisitDocumentPending: true
        };
      })
      .addCase(uploadVisitDocument.fulfilled, (state) => {
        return {
          ...state,
          uploadVisitDocumentPending: false
        };
      })
      .addCase(uploadVisitDocument.rejected, (state, action) => {
        return {
          ...state,
          uploadVisitDocumentPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(uploadFitterSignature.pending, (state) => {
        return {
          ...state,
          uploadFitterSignaturePending: true
        };
      })
      .addCase(uploadFitterSignature.fulfilled, (state) => {
        return {
          ...state,
          uploadFitterSignaturePending: false
        };
      })
      .addCase(uploadFitterSignature.rejected, (state, action) => {
        return {
          ...state,
          uploadFitterSignaturePending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(userResetPassword.pending, (state) => {
        return {
          ...state,
          isResetPassword: true
        };
      })
      .addCase(userResetPassword.fulfilled, (state) => {
        return {
          ...state,
          isResetPassword: false
        };
      })
      .addCase(userResetPassword.rejected, (state, action) => {
        return {
          ...state,
          isResetPassword: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(userResetMFA.pending, (state) => {
        return {
          ...state,
          isResetMFA: true
        };
      })
      .addCase(userResetMFA.fulfilled, (state) => {
        return {
          ...state,
          isResetMFA: false
        };
      })
      .addCase(userResetMFA.rejected, (state, action) => {
        return {
          ...state,
          isResetMFA: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(InviteUser.pending, (state) => {
        return {
          ...state,
          isPendingInviteUser: true,
          isError: false
        };
      })
      .addCase(InviteUser.fulfilled, (state) => {
        return {
          ...state,
          isPendingInviteUser: false
        };
      })
      .addCase(InviteUser.rejected, (state, action) => {
        return {
          ...state,
          isPendingInviteUser: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetEligibilityPayers.pending, (state) => {
        return {
          ...state,
          isEligibilityPayers: true
        };
      })
      .addCase(GetEligibilityPayers.fulfilled, (state, action) => {
        return {
          ...state,
          isEligibilityPayers: false,
          eligibilityPayers: action.payload
        };
      })
      .addCase(GetEligibilityPayers.rejected, (state, action) => {
        return {
          ...state,
          isEligibilityPayers: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(GetDocumentData.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetDocumentData.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          documentDataList: action.payload
        };
      })
      .addCase(GetDocumentData.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = settingsSlice;

export const {
  setBillingChargeMaster,
  setViewMaintenanceByStatus,
  setGetPayerById,
  setUserModalData,
  setUserFilterValue,
  setGetHCPCSRecord,
  setIsVisitDoc,
  setOpenAddRoleModal,
  setViewBillingByStatus,
  setGetFeeScheduleDetails,
  setGetLocationDetailsById,
  setProviderDetails,
  setGetProviderRecord,
  setProviderSourceData,
  setIsProviderImagePresent,
  setAddNewProvider,
  setImageProvider,
  setLocationValue,
  setPayerValue,
  setIsMasterRecordAdded,
  setCheckedStockLocation,
  setSelectedUserLocation,
  setEnableActivePayer,
  setMinDate,
  setDisableDate,
  setDisableSave,
  setSubscriptionCellDescModal,
  setIsMasterPayerUpdated,
  setSaveProviderImage
} = actions;

export default reducer;
