export const ENDPOINTS = {
  // Stock
  GET_STOCK: 'inventoryservice/api/Stock/GetStocksByFilter',
  GET_STOCK_TILES: 'inventoryservice/api/Stock/GetStocksTilesDetails',
  GET_STOCKS_BY_LOCATION: 'inventoryservice/api/Stock/GetStockCounts',
  GET_STOCKS_DETAILS: 'inventoryservice/api/Stock/GetStocksDetails',
  GET_SOURCE_LOCATION_DROPDOWN: 'inventoryservice/api/Stock/GetSourceLocationDropDown',
  UPDATE_STOCK: 'inventoryservice/api/Stock/UpdateStock',
  EDIT_STOCK_DETAILS: 'inventoryservice/api/Stock/EditStockDetails',
  GET_ITEMS_BY_FILTER: 'inventoryservice/api/Stock/GetItemsByFilter',
  ADD_ITEMS_TO_STOCK: 'inventoryservice/api/Stock/AddItemsToStock',
  UPDATE_STOCK_TRANSFER: 'inventoryservice/api/Stock/UpdateStockTransfer',
  COUNTED_DAYS_BY_LOCATION: '/inventoryservice/api/Stock/GetCountedDays',
  STOCK_ACTIVATE_INACTIVATE: 'inventoryservice/api/Stock/StockActiveOrInactive'
};
